import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image"]

  connect() {
    this.currentIndex = 0
    this.lastIndex = this.imageTargets.length - 1
  }

  next() {
    this.currentIndex = this.currentIndex === this.lastIndex ? 0 : this.currentIndex + 1
    this.scrollTo(this.currentIndex)
  }

  prev() {
    this.currentIndex = this.currentIndex === 0 ? this.lastIndex : this.currentIndex - 1
    this.scrollTo(this.currentIndex)
  }

  scrollTo(index) {
    for (const image of this.imageTargets) {
      image.style.left = `${100 * (this.imageTargets.indexOf(image) - index)}%`
    }
  }
}
