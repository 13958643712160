import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  skipToMain(event) {
    event.preventDefault()
    const main = document.querySelector("main") || document.querySelector('[role="main"]')

    if (main) {
      main.scrollIntoView()
      main.focus()
      this.element.blur()
    }
  }
}
