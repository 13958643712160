import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["video"]
  static values = {
    loaded: Boolean,
    itemId: String,
    itemType: String,
  }

  mouseOver() {
    this.playVideo()
  }

  mouseOut() {
    this.pauseVideo()
  }

  playVideo() {
    if (this.hasVideoTarget) {
      const videoPromise = this.videoTarget.play()
      if (typeof videoPromise !== "undefined") {
        videoPromise
          .then(() => {
            if (!this.loadedValue) {
              this.loadedValue = true
            }
          })
          .catch(() => {})
      }
    }
  }

  pauseVideo() {
    if (this.hasVideoTarget) {
      this.videoTarget.pause()
    }
  }
}
