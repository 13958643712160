import { Controller } from "@hotwired/stimulus"
import { FetchResponseError, handleError } from "../utils/errors"
import fetchWithCSRF from "../utils/fetch_with_csrf"

export default class extends Controller {
  static targets = ["content", "overlay", "loading"]
  static classes = ["open", "loadingHidden", "noscroll"]

  open(event) {
    this.showLoadingIndicator()

    fetchWithCSRF(event.detail.url, event.detail.options || {})
      .then((response) => this.responseTextIfOk(response))
      .then((html) => this.showContent(html))
      .then(() => this.setModalState("loaded"))
      .catch((error) => {
        handleError(error)
        this.close()
      })
  }

  close() {
    this.overlayTarget.classList.remove(this.openClass)
    this.setModalState("closed")
    this.removeContent()
  }

  showLoadingIndicator() {
    this.loadingTarget.classList.remove(this.loadingHiddenClass)
    this.overlayTarget.classList.add(this.openClass)
  }

  hideLoadingIndicator() {
    this.loadingTarget.classList.add(this.loadingHiddenClass)
  }

  showContent(html) {
    this.hideLoadingIndicator()
    this.contentTarget.innerHTML = html
    document.body.classList.add(this.noscrollClass)
  }

  removeContent() {
    this.contentTarget.innerHTML = ""
    document.body.classList.remove(this.noscrollClass)
  }

  setModalState(state) {
    this.contentTarget.dataset.modalState = state
  }

  responseTextIfOk(response) {
    if (response.ok) {
      return response.text()
    } else {
      throw new FetchResponseError(response)
    }
  }
}
