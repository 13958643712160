import { Controller } from "@hotwired/stimulus"
import VolumeStore from "../stores/volume_store"

export default class extends Controller {
  static targets = ["rangeInput"]
  static classes = ["muted"]

  connect() {
    this.updateUI()
  }

  toggleMute() {
    VolumeStore.toggleMute()
    this.updateUI()
  }

  adjustVolume(event) {
    VolumeStore.volume = parseInt(event.target.value)
    this.updateUI()
  }

  updateUI() {
    if (this.hasRangeInputTarget) {
      this.rangeInputTarget.value = VolumeStore.volume
      this.rangeInputTarget.setAttribute("aria-valuenow", VolumeStore.volume)
    }

    if (VolumeStore.volume === 0) {
      this.element.classList.add(this.mutedClass)
    } else {
      this.element.classList.remove(this.mutedClass)
    }
  }
}
