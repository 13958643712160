import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.cookieSettingsButton = document.getElementById("cookie-settings")
    if (this.cookieSettingsButton) {
      this.cookieSettingsButton.addEventListener("click", this.handleClick)
    }
  }

  disconnect() {
    if (this.cookieSettingsButton) {
      this.cookieSettingsButton.removeEventListener("click", this.handleClick)
    }
  }

  handleClick(event) {
    if (window.Cookiebot) {
      window.Cookiebot.renew()
      window.addEventListener("CookiebotOnLoad", () => window.location.reload())
    }
  }
}
