import { Controller } from "@hotwired/stimulus"
import Hammer from "hammerjs"

export default class extends Controller {
  static targets = ["root"]
  static values = {
    itemId: String,
    previousPhotoButtonClass: String,
    nextPhotoButtonClass: String,
  }

  connect() {
    Hammer(this.element, {
      domEvents: true,
    })

    const detail = { itemId: this.itemIdValue }
    const event = new CustomEvent("imageDetailInit", { detail })
    window.dispatchEvent(event)
    this.rootTarget.scrollIntoView({ behavior: "smooth", block: "center" })

    if (this.isFirstPhoto()) {
      this.removePreviousPhotoButton()
    } else if (this.isLastPhoto()) {
      this.removeNextPhotoButton()
    }
  }

  onKeyDown(event) {
    switch (event.key) {
      case "ArrowLeft":
        this.goToPreviousPhoto()
        break
      case "ArrowRight":
        this.goToNextPhoto()
        break
    }
  }

  collapsePanel(event) {
    event.target.closest("turbo-frame").textContent = ""
  }

  closeFromInitEvent(event) {
    if (this.itemIdValue === event.detail.itemId) return
    this.rootTarget.closest("turbo-frame").textContent = ""
  }

  goToPreviousPhoto() {
    if (!this.isFirstPhoto()) {
      this.rootTarget.closest("[class^='item-']").previousElementSibling.querySelector("a").click()
    }
  }

  goToNextPhoto() {
    if (!this.isLastPhoto()) {
      this.rootTarget.closest("[class^='item-']").nextElementSibling.querySelector("a").click()
    }
  }

  itemIds() {
    return Array.from(document.querySelectorAll("[id^='item-card-']")).map((element) =>
      element.getAttribute("data-item-id"),
    )
  }

  isFirstPhoto() {
    return this.itemIdValue === this.itemIds()[0]
  }

  isLastPhoto() {
    return this.itemIdValue === this.itemIds()[this.itemIds().length - 1]
  }

  removePreviousPhotoButton() {
    this.element.querySelector(`.${this.previousPhotoButtonClassValue}`).remove()
  }

  removeNextPhotoButton() {
    this.element.querySelector(`.${this.nextPhotoButtonClassValue}`).remove()
  }
}
