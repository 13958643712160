import { Controller } from "@hotwired/stimulus"
import { getCookieWithConsent, setCookieWithConsent } from "../utils/cookies"
import { Consent } from "../utils/consent"

export default class extends Controller {
  static classes = ["hidden"]
  MAX_PAGE_VIEW_COUNT = 5
  PAGE_VIEWS_COOKIE_NAME = "page_view_count"
  SURVEY_COMPLETED_COOKIE_NAME = "feedback_survey_complete"

  DOMAIN_SETTINGS = { domain: undefined } // In marketplace this particular cookie is set with no domain, so we need to unset it here so each site reads and writes the same cookie

  connect() {
    this.incrementPageViewCount()

    const pageViewCount = parseInt(getCookieWithConsent(this.PAGE_VIEWS_COOKIE_NAME, Consent.preferences))
    const surveyCompleted = getCookieWithConsent(this.SURVEY_COMPLETED_COOKIE_NAME, Consent.preferences)
    if (pageViewCount >= this.MAX_PAGE_VIEW_COUNT && surveyCompleted !== "true") {
      const event = new CustomEvent("csatPopupShown")
      this.element.dispatchEvent(event)
    }
  }

  incrementPageViewCount() {
    const pageViewCount = parseInt(getCookieWithConsent(this.PAGE_VIEWS_COOKIE_NAME, Consent.preferences), 10) || 0
    if (pageViewCount === this.MAX_PAGE_VIEW_COUNT) return
    setCookieWithConsent(this.PAGE_VIEWS_COOKIE_NAME, pageViewCount + 1, Consent.preferences, this.DOMAIN_SETTINGS)
  }

  showPopUp() {
    this.element.classList.remove(this.hiddenClass)
  }

  closePopup() {
    this.element.classList.add(this.hiddenClass)
    this.markSurveyAsCompleted()
  }

  onSurveySubmit(e) {
    if (e.origin === "https://envatocommunity.typeform.com" && e.data.type === "form-submit") {
      this.markSurveyAsCompleted()
      window.removeEventListener("message", this.onSurveySubmit)
    }
  }

  markSurveyAsCompleted() {
    setCookieWithConsent(this.SURVEY_COMPLETED_COOKIE_NAME, "true", Consent.preferences, this.DOMAIN_SETTINGS)
  }
}
