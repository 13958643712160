import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  static classes = ["initial"]

  connect() {
    this.containerTarget.classList.remove(this.initialClass)
  }
}
