import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.fallbackApplied = false
  }

  setFallback(e) {
    if (this.fallbackApplied || e.target.dataset.fallback === "") {
      return
    }

    this.fallbackApplied = true
    e.target.src = e.target.dataset.fallback
  }
}
