/* The purpose of this controller is to keep buttons and select values in sync. A use case would be where a UI is required that shows a set of buttons on desktop. However on mobile view, these buttons turn into a regular select dropdown.

# Usage

Add `button-dropdown-sync` controller to root of component.

The following target/ actions should be added to each button:

```
<button value="url/to/first/option"
  data-action="button-dropdown-sync#onClick"
  data-button-dropdown-sync-target="button"
/>
```

Each button should match a select `option` in the dropdown (see `select` below)

When a button is clicked the `data-selected="true"` will be set for the target button. This can be used for styling the active state.

To connect the `select` dropdown, add the following attributes:

```
<select
  data-action="button-dropdown-sync#onChange"
  data-button-dropdown-sync-target="dropdown"
>
  <option value="url/to/first/option">...</option>
</select>
```

Note: the `select > option` values must match the `value` property on the corresponding button.

That's it. The controller will keep the selected option in sync with the button choice and vice versa. */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "dropdown"]

  onClick(event) {
    event.preventDefault()
    this.syncElements(event.target.getAttribute("value"))
  }

  onChange(event) {
    this.syncElements(event.target.value)
  }

  syncElements(value) {
    this.buttonTargets.forEach((el) => {
      el.removeAttribute("data-selected")

      if (el.getAttribute("value") === value) {
        el.dataset.selected = true
      }
    })

    this.dropdownTarget.value = value
  }
}
