import { Controller } from "@hotwired/stimulus"
import { sendAnalyticsEvent } from "@envato/gtm-analytics"

export default class extends Controller {
  sendEvent(event) {
    const payload = this.getAnalyticsPayload()
    sendAnalyticsEvent(payload)
  }

  getAnalyticsPayload() {
    const analyticsCartButtonPayload = this.element.dataset.analyticsCartButtonPayload
    try {
      return analyticsCartButtonPayload ? JSON.parse(analyticsCartButtonPayload) : null
    } catch (error) {
      return null
    }
  }
}
