import padStart from "lodash/padStart"

export const formatLength = (input) => {
  const strippedInput = input.replace(/[^\d:]/g, "")
  if (["", ":"].includes(strippedInput)) {
    return ""
  } else if (strippedInput.includes(":")) {
    return strippedInput
  } else {
    const inputSeconds = parseInt(strippedInput)
    return secondsToMinutesSeconds(inputSeconds)
  }
}

const secondsToMinutesSeconds = (inputSeconds) => {
  const minutes = Math.floor(inputSeconds / 60)
  const seconds = inputSeconds - minutes * 60
  const paddedSeconds = padStart(seconds, 2, "0")
  return [minutes, paddedSeconds].join(":")
}

export const getMinutesSecondsTime = (input) => {
  let matchedSeconds
  let matchedMinutes

  if (Number(input)) {
    matchedMinutes = 0
    matchedSeconds = Number(input)
  } else {
    const inputString = String(input)
    const format = /^(\d*):?(\d+)$/
    const match = inputString.match(format)

    if (!match) {
      return ""
    }

    matchedMinutes = match[1] ? Number(match[1]) : 0
    matchedSeconds = match[2] ? Number(match[2]) : 0
  }

  const additionalMinutes = Math.floor(matchedSeconds / 60)
  const seconds = Math.floor(matchedSeconds) - additionalMinutes * 60
  const minutes = matchedMinutes + additionalMinutes
  const secondsFormatted = seconds < 9.5 ? "0" + seconds.toFixed(0) : String(seconds.toFixed(0))
  const minutesFormatted = String(minutes)

  return `${minutesFormatted}:${secondsFormatted}`
}
