export const openModal = (path, options = {}) => {
  const customEvent = new CustomEvent("command:modal:open", {
    detail: {
      url: modalURLStringFromPath(path),
      options,
    },
  })
  window.dispatchEvent(customEvent)
}

export const closeModal = () => {
  window.dispatchEvent(new CustomEvent("command:modal:close"))
}

export const openSignInModal = () => {
  openModal("/storefront/sign_in")
}

const modalURLStringFromPath = (path) => {
  const url = new URL(path, window.location)
  url.searchParams.set("modal", "1")
  return url.toString()
}
