import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["itemLink"]
  static values = {
    detailUrl: String,
  }

  connect() {
    this.itemLinkTarget.href = this.detailUrlValue
  }
}
