import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["categoriesMenu", "userMenu", "categoriesTemplate"]

  closeMenu(e) {
    this.categoriesMenuTarget.dataset.visible = "false"
    this.userMenuTarget.dataset.visible = "false"
  }

  openUserMenu(e) {
    e.preventDefault()

    this.userMenuTarget.dataset.visible = "true"
  }

  openCategoriesMenu(e) {
    e.preventDefault()

    this.populateMobileMenuFromTemplate()
    this.categoriesMenuTarget.dataset.visible = "true"
  }

  populateMobileMenuFromTemplate() {
    if (this.mobileMenuPopulatedOnce !== true) {
      this.mobileMenuPopulatedOnce = true
      const menuHTML = this.categoriesTemplateTarget.content.cloneNode(true)
      this.categoriesMenuTarget.append(menuHTML)
    }
  }
}
