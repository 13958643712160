import { Controller } from "@hotwired/stimulus"
import { handleError } from "../utils/errors"
import { isSignedOut } from "../utils/session"
import { autoSignIn } from "@envato/sso-forms-standalone"
import localSession from "../utils/local_session"
export default class extends Controller {
  initialize() {
    if (isSignedOut() && this.isAutoSignInRequired) {
      autoSignIn(this.ssoServerDomain, (token) => localSession.signIn(token))
    }
  }

  onError = (message, e) => {
    // we don't care to report network timeouts when doing auto sign in
    if (message !== "XMLHttpRequest.ontimeout") handleError(e)
  }

  get isAutoSignInRequired() {
    const urlParams = new URL(document.location.toString()).searchParams
    return urlParams.get("auto_signin") === "true"
  }

  get ssoServerDomain() {
    return document.head.querySelector('meta[name="sso_server_domain"]').content
  }
}
