class CartEntrySummaryStore {
  constructor() {
    this.cartEntryStore = {}
  }

  clear() {
    this.cartEntryStore = {}
  }

  cartEntriesCount() {
    return this.cartEntries.length
  }

  replaceCartEntries(newCartEntries) {
    this.clear()
    this.saveCartEntries(newCartEntries)
  }

  saveCartEntries(newCartEntries) {
    newCartEntries.forEach((newCartEntry) => {
      this.cartEntryStore[newCartEntry.id] = newCartEntry
    })
    this.emitEvent()
  }

  saveCartEntry(newCartEntry) {
    this.cartEntryStore[newCartEntry.id] = newCartEntry
    this.emitEvent()
  }

  isItemIdInCart(itemId) {
    return this.cartEntries.some((entry) => entry.item_id === itemId)
  }

  numberOfCurrentItemInCart(itemId) {
    return this.cartEntries.reduce((acc, entry) => {
      return entry.item_id === itemId ? ++acc : acc
    }, 0)
  }

  get cartEntries() {
    return Object.values(this.cartEntryStore)
  }

  get isEmpty() {
    return this.cartEntriesCount() === 0
  }

  emitEvent() {
    const event = new Event("cartStoreUpdated")
    window.dispatchEvent(event)
  }
}

const cartStoreInstance = new CartEntrySummaryStore()

export default cartStoreInstance
