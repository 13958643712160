// On form submission, disable button/input elements that have data-disable="true", and re-enable after the submission
// has finished
export const disableButtonHandler = (document) => {
  const setDisabledState = (event, disabledState) => {
    const button = event.detail.formSubmission.submitter
    if (button.dataset.disable === "true") {
      button.disabled = disabledState
    }
  }
  document.addEventListener("turbo:submit-start", (event) => {
    setDisabledState(event, true)
  })
  document.addEventListener("turbo:submit-end", (event) => {
    setDisabledState(event, false)
  })
}
