export const allDomains = [
  "activeden.test",
  "activeden-staging.net",
  "activeden.net",

  "audiojungle.test",
  "audiojungle-staging.net",
  "audiojungle.net",

  "themeforest.test",
  "themeforest-staging.net",
  "themeforest.net",

  "videohive.test",
  "videohive-staging.net",
  "videohive.net",

  "graphicriver.test",
  "graphicriver-staging.net",
  "graphicriver.net",

  "3docean.test",
  "3docean-staging.net",
  "3docean.net",

  "codecanyon.test",
  "codecanyon-staging.net",
  "codecanyon.net",

  "photodune.test",
  "photodune-staging.net",
  "photodune.net",

  "elements.envato.com",
  "author.envato.com",
  "envato.com",
  "account.envato.com",
  "forums.envato.com",
]
