// Turbo Frames don't work with redirects (at the time of writing).
// https://discuss.hotwire.dev/t/break-out-of-a-frame-during-form-redirect/1562
//
// This controller let's us redirect out of a frame
//
// USAGE:
//
// <turbo-frame id="modal">
//   <span data-controller="turbo-frame-redirect" data-turbo-frame-redirect-url-value="/cart"></span>
// </turbo-frame>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  connect() {
    window.location = this.urlValue
  }
}
