import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["price", "pricePreDiscount", "priceDiscount", "licenseLabel", "supportLabel"]

  static classes = ["hidden"]
  static values = {
    selectedLicense: String,
    selectedSupport: String,
    pricingData: Object,
  }

  licenseSelected(event) {
    this.selectedLicenseValue = event.target.value
    this.updateInfo()
  }

  supportSelected(event) {
    this.selectedSupportValue = event.target.value
    this.updateInfo()
  }

  submit(_event) {
    this.element.submit()
  }

  updateInfo() {
    this.updatePrice()
    this.updateLicense()
    this.updateSupport()
  }

  updatePrice() {
    this.priceTarget.innerHTML = this.selectedSupportOffering.price_string
    this.pricePreDiscountTarget.innerHTML = this.selectedSupportOffering.original_price_string
    this.priceDiscountTarget.innerHTML = this.selectedSupportOffering.price_string
    if (this.shouldShowDiscountPricing) {
      this.showDiscountPricing()
    } else {
      this.showNormalPricing()
    }
  }

  showNormalPricing() {
    this.priceTarget.classList.remove(this.hiddenClass)
    this.pricePreDiscountTarget.classList.add(this.hiddenClass)
    this.priceDiscountTarget.classList.add(this.hiddenClass)
  }

  showDiscountPricing() {
    this.priceTarget.classList.add(this.hiddenClass)
    this.pricePreDiscountTarget.classList.remove(this.hiddenClass)
    this.priceDiscountTarget.classList.remove(this.hiddenClass)
  }

  updateLicense() {
    if (this.hasLicenseLabelTarget) {
      this.licenseLabelTarget.innerHTML = this.selectedLicense.label
      this.licenseLabelTarget.href = this.selectedLicense.terms_url
    }
  }

  updateSupport() {
    if (this.hasSupportLabelTarget) {
      this.supportLabelTarget.innerHTML = this.selectedSupportOffering.label
    }
  }

  get selectedLicense() {
    return this.pricingDataValue.licenses.find((license) => license.key === this.selectedLicenseValue)
  }

  get selectedSupportOffering() {
    return this.selectedLicense.support_offerings.find((plan) => plan.key === this.selectedSupportValue)
  }

  get shouldShowDiscountPricing() {
    return this.selectedSupportOffering.is_discounted
  }
}
