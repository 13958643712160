// When a `fetch` request returns a response with a `X-CSRF-Token` header, set it as a meta tag so
// that Rails UJS can find and use it in subsequent requests.
// Use this class when making requests to Storefront (Rails), but not if making requests to
// external domains

import fetchWrapper from "./fetch_wrapper"
import { throwUnlessOk } from "./errors"

const handleCSRFTokens = (response) => {
  if (response.headers.has("X-CSRF-Token")) {
    const csrfToken = response.headers.get("X-CSRF-Token")
    const meta = document.querySelector("meta[name=csrf-token]")
    meta.setAttribute("content", csrfToken)
  }
  return response
}

const csrfToken = () => {
  const meta = document.querySelector("meta[name=csrf-token]")
  return meta && meta.content
}

const getCSRFToken = () => {
  return fetchWithCSRF("/my/token").then(throwUnlessOk)
}

export default function fetchWithCSRF(url, options = {}) {
  if (options.method && !["GET", "HEAD"].includes(options.method)) {
    const token = csrfToken()

    if (!token) {
      return getCSRFToken().then(() => fetchWithCSRF(url, options))
    }

    options.headers = { ...options.headers, "X-CSRF-Token": token }
  }

  return fetchWrapper(url, options).then(handleCSRFTokens)
}
