// ## Overview:
//
// This controller enables simple tabbling functionality for front-end controlled tabs
//
// - The controller cares about `buttons` and `tabs`
// - Active state `data-active` is set for tab when selected
// - Active state behaviour should be controlled with CSS
// - Uses an `index` value to track what tab should be selected
//
// ## Example
//
// <div data-controller="tabs">
//   <nav class="tabs">
//     <button data-index="0" data-tabs-target="button" data-action="click->tabs#onTabClick" data-active="true">
//       First tab
//     </button>
//     <button data-index="1" data-tabs-target="button" data-action="click->tabs#onTabClick">
//       Second tab
//     </button>
//   </nav>
//   <section>
//     <div data-tabs-target="tab" data-active="true">First tab content</div>
//     <div data-tabs-target="tab">Second tab content</div>
//   </section>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "tab"]

  onTabClick(event) {
    const targetTab = event.target.closest("button")

    this.buttonTargets.forEach((btn) => (btn.dataset.active = false))
    targetTab.dataset.active = true

    this.setActiveTab(parseInt(targetTab.dataset.index, 10))
  }

  displaySelectedTabFromDropdown(event) {
    this.setActiveTab(parseInt(event.target.value, 10))
  }

  setActiveTab(targetIndex) {
    this.tabTargets.forEach((tab, index) => {
      tab.dataset.active = index === targetIndex
    })
  }
}
