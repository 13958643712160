import { Controller } from "@hotwired/stimulus"
import { Consent, deferRun, userHasOptedOutOfCookiesForCategory } from "../utils/consent"
import { removeCookieWithDefaultOptions } from "../utils/cookies"

export default class extends Controller {
  connect() {
    // We don't want to do this when displaying the turbo in-memory cached version.
    // After a few more milliseconds the network request will finish,
    // and this connect() will run again without this preview attribute set.
    if (document.documentElement.hasAttribute("data-turbo-preview")) return

    const allowClarityCookies = () => {
      window.clarity("consent")
    }

    // Run this callback when we know the user's cookie preferences, or wait for consent before using them.
    // Clarity will still run and collect data either way.
    deferRun(allowClarityCookies, Consent.statistics)

    if (typeof window !== "undefined") {
      this.handleCookiebotAcceptDeclineEventClarity = this.handleCookiebotAcceptDeclineEventClarity.bind(this)
      window.addEventListener("CookiebotOnAccept", this.handleCookiebotAcceptDeclineEventClarity)
      window.addEventListener("CookiebotOnDecline", this.handleCookiebotAcceptDeclineEventClarity)
    }
  }

  // On declining statistics consent, we delete Clarity-related cookies
  handleCookiebotAcceptDeclineEventClarity() {
    if (userHasOptedOutOfCookiesForCategory(Consent.statistics)) {
      // Manually delete any existing Clarity cookies
      // spellr:disable
      ;["_clck", "_clsk", "CLID", "ANONCHK", "MR", "MUID", "SM"].forEach((cookieName) => {
        const optionsForRemoval = { path: "/" }
        removeCookieWithDefaultOptions(cookieName, optionsForRemoval)
      })
      // spellr:enable
    }
  }
}
