// This controller uses Google Analytics linker to track users movements across the different Envato
// websites (i.e user clicking a link on CC to go to AJ).
//
// GA linker docs - https://developers.google.com/analytics/devguides/collection/analyticsjs/linker
//
// Here we are using GA's manual link decoration instead of it autolinker. This is because
// Envato websites, through Cookiebot, must determine when visitors have allowed statistic tracking
// before updating a link's URL.

import ga from "../utils/ga"
import { allDomains } from "../utils/site_list"
import { Controller } from "@hotwired/stimulus"
import { Consent, consented } from "../utils/consent"

export default class extends Controller {
  decorateLink(event) {
    if (consented(Consent.statistics)) {
      const link = event.target.closest("a")
      if (!link) return

      // for preview domains
      const isNewWindowLink = document.location.hostname === link.hostname && link.target === "_blank"

      // for internal domains
      const isInternalLink = allDomains
        .filter((domain) => domain !== document.location.hostname)
        .includes(link.hostname)

      if (isInternalLink || isNewWindowLink) {
        ga("linker:decorate", link)
      }
    }
  }
}
