class VolumeStore {
  constructor(muted) {
    this._muteIsOn = muted
    this._volume = 80
  }

  get volume() {
    return this._muteIsOn ? 0 : this._volume
  }

  get volumeAsDecimal() {
    return this.volume / 100.0
  }

  set volume(volume) {
    this._volume = volume
    this._muteIsOn = volume === 0
    this.emitEvent()
  }

  toggleMute() {
    this._muteIsOn = !this._muteIsOn
    this.emitEvent()
    return this._muteIsOn
  }

  emitEvent() {
    const event = new Event("volumeUpdated")
    window.dispatchEvent(event)
  }
}

const isMuted = document.body.dataset.site === "videohive"
const volumeStoreInstance = new VolumeStore(isMuted)

export default volumeStoreInstance
