const encodeFormData = (data) => {
  return new URLSearchParams(data).toString()
}

const ssoVerificationPath = "/sso/verify_token"

export default {
  signIn: (token, onError) => {
    return fetch(ssoVerificationPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encodeFormData({ token }),
    })
      .then((response) => {
        if (response.ok) {
          reloadWindowWithoutAutoSignInParams()
        } else {
          throw new Error("A server error has occurred")
        }
      })
      .catch((message) => {
        if (onError) {
          onError(message)
        } else {
          throw message
        }
      })
  },

  signOut: () => {},
}

function reloadWindowWithoutAutoSignInParams() {
  const url = new URL(window.location.href)
  url.searchParams.delete("auto_signin")
  window.location.replace(url.toString())
}
