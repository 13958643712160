import { Controller } from "@hotwired/stimulus"
import CartEntrySummaryStore from "../stores/cart_entry_summary_store"

export default class extends Controller {
  static classes = ["inCart"]
  static values = { itemId: Number, inCart: Boolean }

  connect() {
    this.updateFromStore()
  }

  updateFromStore() {
    if (CartEntrySummaryStore.isItemIdInCart(this.itemIdValue)) {
      this.element.classList.add(this.inCartClass)
      this.inCartValue = true
    } else {
      this.element.classList.remove(this.inCartClass)
      this.inCartValue = false
    }
  }
}
