// ## Overview:
//
// This controller facilitates show more/less type of interactions.
//
// - The controller element tracks the current state
// - It should have 1 or more 'toggle' targets (itself, or child elements)
// - It exposes 3 actions: toggle, expand, collapse
// - The actions can be called by the element itself, or any child standard stimulus way)
// - When those actions are called:
//    - The state (in markup) on the controller element will be updated
//    - The state (in session storage) will be optional updated
//    - The appropriate expand or collapse functions will be called for each toggle target
//
// ## Controller Element Values
//
// The follow values can be applied to the controller element
//
// Read/write (to track current state):
// - data-toggler-expanded-value="<true/false>" => defaults to false. Tracks current state.
//
// Read only (to control behaviour):
// - data-toggler-toggle-on-connect-value="<true/false>" => defaults to false. If true, will call toggle on connect.
//     This reduces ERB logic from having to set the current state for all toggleable properties
// - data-toggler-session-storage-key-value => remembers the state in session storage.
//     If used, toggle-on-connect must also be used
//
// ## Toggleable Attributes (for toggle targets)
//
// The follow attributes can be set on any toggle target.
// They are all optional. If they are present, they will control what gets toggled on the target
//
// - data-toggler-<expanded/collapsed>-aria-label => will set aria-label
// - data-toggler-<expanded/collapsed>-aria-hidden => will set aria-hidden
// - data-toggler-<expanded/collapsed>-text => will set innerHTML
// - data-toggler-expanded-class => will add/remove the expanded class based on the state.
//     (note - there is no support for a collapsed class)
//
// ## Example:
//
// <div
//   data-controller="toggler"
//   data-toggler-expanded-value="false"
//   data-toggler-toggle-on-connect-value="false"
// >
//   <a
//     data-action="click->toggler#toggle"
//     data-toggler-target="toggle"
//     data-toggler-expanded-aria-label="Collapse Text"
//     data-toggler-collapsed-aria-label="Expand Text"
//   >
//     Show more
//   </a>
//   <div
//     data-toggler-target="toggle"
//     data-toggler-expanded-class="expanded"
//   >
//     Hello world
//   </div>
// </div>

import { Controller } from "@hotwired/stimulus"
import { getSessionStorageWithConsent, setSessionStorageWithConsent } from "../utils/storage"
import { Consent } from "../utils/consent"

export default class extends Controller {
  static targets = ["toggle"]
  static values = {
    expanded: Boolean,
    toggleOnConnect: Boolean,
    sessionStorageKey: String,
  }

  connect() {
    if (this.toggleOnConnectValue) {
      if (this.hasExpandedValueInSessionStorage()) {
        this.expandedValue = this.readExpandedValueFromSessionStorage()
      }
      this.updateUI()
    }
  }

  toggle(e) {
    e.preventDefault()
    this.expandedValue = !this.expandedValue
    this.update()
  }

  expand() {
    this.expandedValue = true
    this.update()
  }

  collapse() {
    this.expandedValue = false
    this.update()
  }

  update() {
    this.updateStorage()
    this.updateUI()
  }

  updateStorage() {
    if (this.hasSessionStorageKeyValue) {
      this.writeExpandedValueToSessionStorage()
    }
  }

  updateUI() {
    this.toggleTargets.forEach((element) => {
      if (this.expandedValue) {
        this.expandElement(element)
      } else {
        this.collapseElement(element)
      }
    })
  }

  collapseElement(element) {
    this.setAttrFromDataset(element, "aria-label", "togglerCollapsedAriaLabel")
    this.setAttrFromDataset(element, "aria-hidden", "togglerCollapsedAriaHidden")
    if ("togglerCollapsedText" in element.dataset) {
      element.innerHTML = element.dataset.togglerCollapsedText
    }
    if ("togglerExpandedClass" in element.dataset) {
      element.classList.remove(element.dataset.togglerExpandedClass)
    }
  }

  expandElement(element) {
    this.setAttrFromDataset(element, "aria-label", "togglerExpandedAriaLabel")
    this.setAttrFromDataset(element, "aria-hidden", "togglerExpandedAriaHidden")
    if ("togglerExpandedText" in element.dataset) {
      element.innerHTML = element.dataset.togglerExpandedText
    }
    if ("togglerExpandedClass" in element.dataset) {
      element.classList.add(element.dataset.togglerExpandedClass)
    }
  }

  setAttrFromDataset(element, attrName, datasetKey) {
    if (datasetKey in element.dataset) {
      element.setAttribute(attrName, element.dataset[datasetKey])
    }
  }

  hasExpandedValueInSessionStorage() {
    const sessionStorageValue = getSessionStorageWithConsent(this.sessionStorageKeyValue, Consent.preferences)
    return sessionStorageValue === "collapsed" || sessionStorageValue === "expanded"
  }

  readExpandedValueFromSessionStorage() {
    const sessionStorageValue = getSessionStorageWithConsent(this.sessionStorageKeyValue, Consent.preferences)
    return sessionStorageValue !== "collapsed"
  }

  writeExpandedValueToSessionStorage() {
    if (this.expandedValue) {
      setSessionStorageWithConsent(this.sessionStorageKeyValue, "expanded", Consent.preferences)
    } else {
      setSessionStorageWithConsent(this.sessionStorageKeyValue, "collapsed", Consent.preferences)
    }
  }
}
