import { Controller } from "@hotwired/stimulus"
import FavoritesStore from "../stores/favorites_store"
import { throwUnlessOk, handleError } from "../utils/errors"
import { isSignedIn } from "../utils/session"
import fetchWithCSRF from "../utils/fetch_with_csrf"

export default class extends Controller {
  static get targets() {
    return ["item"]
  }

  connect() {
    if (isSignedIn()) {
      this.loadFavorites()
    }
  }

  loadFavorites() {
    const itemIds = this.itemIdsFromTargets()
    if (itemIds.length === 0) {
      return
    }

    const url = new URL("/my/favorites", window.location)
    url.searchParams.append("item_ids", itemIds)

    fetchWithCSRF(url)
      .then(throwUnlessOk)
      .then((response) => response.json())
      .then((data) => FavoritesStore.addFavorites(data.favorites))
      .catch((error) => handleError(error))
  }

  itemIdsFromTargets() {
    return this.itemTargets.map((element) => element.dataset.itemId)
  }
}
