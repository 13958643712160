import { Controller } from "@hotwired/stimulus"
import { formatLength } from "../utils/time"

export default class extends Controller {
  static targets = ["input"]

  submit(event) {
    event.preventDefault()
    this.inputTargets.forEach((input) => {
      if (input.value) {
        const formattedValue = formatLength(input.value)
        input.value = formattedValue
      }
    })
    this.element.submit()
  }
}
