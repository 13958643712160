const ga = (...args) => {
  // So we have a problem with how we send analytics events.
  // We have a bunch of stimulus code that sends ga() events during the stimulus connect()/initialize() methods.
  // Unfortunately those stimulus methods run TWICE is a page is cached in stimulus memory.
  // This results in the ga() events getting sent TWICE as well! Whoops!

  // So... we can tell when stimulus is in "cached" mode by looking at the "data-turbo-preview" attribute.
  // We're just going to ignore any ga() calls that happen during this "cached" mode, and wait for them to fire
  // for real real a few milliseconds later when the real page is loaded from network.
  // As per: https://turbo.hotwired.dev/handbook/building#detecting-when-a-preview-is-visible
  if (!document.documentElement.hasAttribute("data-turbo-preview")) {
    // We're on a real page load, safe to send ga events.
    if (typeof window !== "undefined" && typeof window.ga === "function") {
      window.ga(...args)
    }
  }
}

export default ga
