import { Controller } from "@hotwired/stimulus"
import { throwUnlessOk } from "../utils/errors"

export default class extends Controller {
  static targets = ["root"]
  static values = { url: String, site: String }

  connect() {
    fetch("/storefront_api/csat")
      .then(throwUnlessOk)
      .then((response) => response.json())
      .then((json) => {
        const queryString = Object.keys(json)
          .map((key) => key + "=" + json[key])
          .join("&")
        const url = `${this.urlValue}page=${window.location.pathname}&${queryString}`
        this.showPage(url)
      })
      .catch(() => {
        this.showPage(this.urlValue)
      })
  }

  showPage(url) {
    this.rootTarget.innerHTML = `<iframe src='${url}&market=${this.siteValue}'></iframe>`
  }
}
