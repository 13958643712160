import { Controller } from "@hotwired/stimulus"
import fetchWithCSRF from "../utils/fetch_with_csrf"
import encodeFormData from "../utils/encode_form_data"
import { handleError } from "../utils/errors"

export default class extends Controller {
  static targets = ["form", "submitButton"]

  toggleBookmark(event) {
    event.preventDefault()
    fetchWithCSRF(this.formTarget.action, {
      method: "POST",
      body: encodeFormData(this.formTarget),
    })
      .then((response) => this.handleResponse(response))
      .catch((error) => handleError(error))
  }

  handleResponse(response) {
    if (response.ok) {
      if (this.element.dataset.bookmarked === "true") {
        this.element.dataset.bookmarked = "false"
        this.formMethodTarget.value = "post"
      } else {
        this.formMethodTarget.value = "delete"
        this.element.dataset.bookmarked = "true"
      }
    } else {
      console.log(response.error)
    }
  }
}
