import { Controller } from "@hotwired/stimulus"
import { openModal } from "../utils/modal"
import { isSignedOut } from "../utils/session"

export default class extends Controller {
  open(e) {
    e.preventDefault()
    isSignedOut() ? this._redirectToSignInWithAction() : openModal(this.element.href)
  }

  _redirectToSignInWithAction() {
    const itemId = this._itemId
    const returnTo = window.location.pathname + window.location.search
    window.location = `/sign_in?action_name=collection&resource_id=${itemId}&return_to=${returnTo}`
  }

  get _itemId() {
    return this.element.dataset.itemId
  }
}
