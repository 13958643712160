import { Controller } from "@hotwired/stimulus"
import CartEntrySummaryStore from "../stores/cart_entry_summary_store"
import { throwUnlessOk, handleError } from "../utils/errors"
import fetchWithCSRF from "../utils/fetch_with_csrf"

export default class extends Controller {
  static values = {
    url: String,
  }

  initialize() {
    this.loadCartEntries()
  }

  loadCartEntries() {
    fetchWithCSRF(this.urlValue)
      .then(throwUnlessOk)
      .then((response) => response.json())
      .then((data) => CartEntrySummaryStore.replaceCartEntries(data.cart_entries))
      .catch((error) => handleError(error))
  }
}
