import { Controller } from "@hotwired/stimulus"
import Siema from "siema"

export default class extends Controller {
  static targets = ["track", "slideItem"]

  connect() {
    // To avoid the "TypeError: Cannot read property 'cloneNode' of undefined"
    // error we need to make sure we have our 8 div slider items in the DOM
    // before we initialize the Siema slider.
    if (this.slideItemTargets.length === 8) {
      this.initializeSlider()
    }
  }

  initializeSlider() {
    this.slider = new Siema({
      selector: this.trackTarget,
      loop: true,
      draggable: false,
      multipleDrag: false,
      perPage: {
        100: 1,
        540: 2,
        690: 3,
        1000: 4,
      },
    })
    this.trackTarget.dataset.sliderInitialized = "true"
  }

  nextSlide() {
    this.slider.next()
  }

  prevSlide() {
    this.slider.prev()
  }
}
