import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inner", "track"]

  initialize() {
    this.innerDivWidth = 0
    this.trackDivWidth = 0
    this.trackAndInnerDivDiff = 0
    this.offsetAmount = 100
  }

  connect() {
    this.isSliderRequired()
    this.calculateButtonVisibility()
  }

  isSliderRequired() {
    this.setTrackAndInnerDivVars()
    this.calculateButtonVisibility()
  }

  calculateButtonVisibility() {
    const showPrevButton = this.innerTarget.scrollLeft > 0
    const showNextButton = this.innerTarget.scrollLeft < this.trackAndInnerDivDiff

    this.innerTarget.dataset.showPreviousButton = showPrevButton
    this.innerTarget.dataset.showNextButton = showNextButton
  }

  setTrackAndInnerDivVars() {
    this.innerDivWidth = this.innerTarget.offsetWidth
    this.trackDivWidth = this.trackTarget.offsetWidth
    this.trackAndInnerDivDiff = this.trackDivWidth - this.innerDivWidth
  }

  slidePrevious() {
    this.innerTarget.scrollBy({ left: -this.offsetAmount, top: 0, behavior: "smooth" })
    this.calculateButtonVisibility()
  }

  slideNext() {
    this.innerTarget.scrollBy({ left: this.offsetAmount, top: 0, behavior: "smooth" })
    this.calculateButtonVisibility()
  }
}
