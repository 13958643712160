import { Controller } from "@hotwired/stimulus"
import { openModal, closeModal } from "../utils/modal"

export default class extends Controller {
  static values = {
    require: String,
    url: String,
    method: String,
  }

  open(e) {
    e.preventDefault()
    openModal(this.modalUrl(), this.options())
  }

  close(e) {
    e.preventDefault()
    closeModal()
  }

  options() {
    if (this.hasMethodValue) {
      return { method: this.methodValue }
    } else {
      return {}
    }
  }

  modalUrl() {
    if (this.hasUrlValue) {
      return this.urlValue
    } else {
      return this.element.href
    }
  }
}
