import { consented } from "./consent"
import Cookies from "js-cookie"

// Domain cookies are ignored when visiting an IP address, and Cypress makes
// requests via IP by default. Reconfiguring it to use DNS is a little involved.
// This method will conditionally set a host-only cookie in test in order to
// work around the IP issue, but set domain cookies in all other environments.
const defaultCookieOptions = () => {
  const domain = document.body.dataset.siteDomain

  if (!domain || document.body.dataset.railsEnv === "test") {
    return {}
  } else {
    return { domain }
  }
}

export const getCookieWithConsent = (itemKey, consentType, defaultValue) => {
  if (consented(consentType)) {
    // eslint-disable-next-line banhammer/no-restricted-functions
    return Cookies.get(itemKey) || defaultValue
  }

  return defaultValue
}

export const setCookieWithConsent = (itemKey, itemValue, consentType, cookieOptions) => {
  if (consented(consentType)) {
    // eslint-disable-next-line banhammer/no-restricted-functions
    Cookies.set(itemKey, itemValue, { ...defaultCookieOptions(), ...cookieOptions })
  }
}

export const removeCookieWithDefaultOptions = (itemKey, cookieOptions) => {
  Cookies.remove(itemKey, { ...defaultCookieOptions(), ...cookieOptions })
}
