export class FetchResponseError extends Error {
  constructor(response) {
    super(`${response.url} responded with status: ${response.status} ${response.statusText}`)
    this.response = response
  }
}

export const throwUnlessOk = (response) => {
  if (response.ok) {
    return response
  } else {
    throw new FetchResponseError(response)
  }
}

export const handleError = (error) => {
  if (process.env.PRINT_ERROR_TO_CONSOLE === "true") {
    console.error({ error })
  }
  if (typeof window !== "undefined" && typeof window.rollbar === "function") {
    window.rollbar.error(error)
  }
}
