/* The purpose of this controller is to support hyperlink style navigation using the HTML `select` element.

# Usage

Add `data-controller=dropdown-navigation` and `data-action=dropdown-navigation#navigate` to any HTML select
element and ensure every `option` element has its `value` attribute set to a URL (could also be relative)
like in the example below.

```
<select
  data-controller="dropdown-navigation"
  data-action="dropdown-navigation#navigate">
>
  <option value="/page-1">page 1</option>
  <option value="/page-2">page 2</option>
</select>
```
*/

import { Controller } from "@hotwired/stimulus"
import { visit } from "@hotwired/turbo"

export default class extends Controller {
  navigate(_event) {
    visit(this.element.value)
  }
}
