import { Controller } from "@hotwired/stimulus"
import FavoritesStore from "../stores/favorites_store"
import { isSignedOut } from "../utils/session"
import fetchWithCSRF from "../utils/fetch_with_csrf"
import encodeFormData from "../utils/encode_form_data"
import { FetchResponseError, handleError } from "../utils/errors"

export default class extends Controller {
  static get targets() {
    return ["formMethod", "favoriteButton"]
  }

  updateFromStore() {
    const favorited = FavoritesStore.isItemIdFavorited(this._itemId)
    if (favorited) {
      this.formMethodTarget.value = "delete"
      this.favoriteButtonTarget.dataset.favorited = "true"
    } else {
      this.formMethodTarget.value = "post"
      this.favoriteButtonTarget.dataset.favorited = "false"
    }
  }

  submit(event) {
    event.preventDefault()
    if (isSignedOut()) {
      this.redirectToSignInWithAction()
      return
    }
    const body = encodeFormData(this.element)
    this.ajaxSend()
    fetchWithCSRF(this.element.action, { method: "POST", body })
      .then((response) => this.handleResponse(response))
      .catch((error) => {
        this.resetState()
        handleError(error)
      })
  }

  redirectToSignInWithAction() {
    const returnTo = window.location.pathname + window.location.search
    const itemId = this._itemId
    window.location = `/sign_in?action_name=favorite&resource_id=${itemId}&return_to=${returnTo}`
  }

  handleResponse(response) {
    if (!response.ok) {
      this.resetState()
      throw new FetchResponseError(response)
    }
    this.ajaxSuccess()
  }

  // As soon as the request is set to the server, we want to update the UI so it's very responsive.
  // The actual change to the data store happens after ajaxSuccess
  // TODO: consider using disable-with
  ajaxSend(_event) {
    this.favoriteButtonTarget.disabled = true
    if (this.favoriteButtonTarget.dataset.favorited === "true") {
      this.favoriteButtonTarget.dataset.favorited = "false"
    } else {
      this.favoriteButtonTarget.dataset.favorited = "true"
    }
  }

  // On success, just update the store, which will in turn trigger updateFromStore
  ajaxSuccess() {
    if (this.formMethodTarget.value === "delete") {
      this.formMethodTarget.value = "post"
      FavoritesStore.removeFavoritedItemId(this._itemId)
    } else {
      this.formMethodTarget.value = "delete"
      FavoritesStore.addFavoritedItemId(this._itemId)
    }
    this.favoriteButtonTarget.disabled = false
  }

  // If there is an error, then the favoriting or unfavoriting didn't work
  // So, we just want to revert to the state from the store and let rollbar catch the error
  resetState() {
    this.favoriteButtonTarget.dataset.favoritingError = 1
    this.favoriteButtonTarget.disabled = false
    this.updateFromStore()
  }

  get _itemId() {
    return this.favoriteButtonTarget.dataset.itemId
  }
}
