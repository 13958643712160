import { Controller } from "@hotwired/stimulus"
import CartEntrySummaryStore from "../stores/cart_entry_summary_store"

export default class extends Controller {
  static classes = ["hidden"]
  static targets = ["goToCheckout", "itemAddedNotification", "notificationText"]
  static values = {
    addedToCart: String,
    addedMultiToCart: String,
    itemId: Number,
  }

  connect() {
    this.updateFromStore()
  }

  updateFromStore() {
    if (CartEntrySummaryStore.isEmpty) {
      this.goToCheckoutTarget.classList.add(this.hiddenClass)
    } else {
      this.goToCheckoutTarget.classList.remove(this.hiddenClass)
    }
    const count = CartEntrySummaryStore.numberOfCurrentItemInCart(this.itemIdValue)
    if (count > 0) {
      this.notificationTextTarget.innerHTML =
        count > 1 ? this.addedMultiToCartValue.replace("%{item_count}", count) : this.addedToCartValue
      this.itemAddedNotificationTarget.classList.remove(this.hiddenClass)
    } else {
      this.itemAddedNotificationTarget.classList.add(this.hiddenClass)
    }
  }
}
