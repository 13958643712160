import { Controller } from "@hotwired/stimulus"
import { sendAnalyticsEvent } from "@envato/gtm-analytics"

export default class extends Controller {
  static targets = ["input"]

  submit(event) {
    const searchTerm = this.inputTarget.value

    this.sendSearchEvent(searchTerm)
  }

  sendSearchEvent(searchTerm) {
    sendAnalyticsEvent({
      eventName: "search",
      eventType: "user",
      searchTerm,
    })
  }
}
