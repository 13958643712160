import { consented } from "./consent"

const getStorage = (storageType, itemKey, consentType) => {
  if (consented(consentType)) {
    const value = storageType.getItem(itemKey)

    try {
      return JSON.parse(value)
    } catch (error) {
      return value
    }
  }
}

const setStorage = (storageType, itemKey, itemValue, consentType) => {
  if (consented(consentType)) {
    return storageType.setItem(itemKey, itemValue)
  }
}

const removeStorage = (storageType, itemKey, consentType) => {
  if (consented(consentType)) {
    storageType.removeItem(itemKey)
  }
}

export const setLocalStorageWithConsent = (itemKey, itemValue, consentType) =>
  // eslint-disable-next-line storage/no-localstorage
  setStorage(window.localStorage, itemKey, itemValue, consentType)

export const getLocalStorageWithConsent = (itemKey, consentType) =>
  // eslint-disable-next-line storage/no-localstorage
  getStorage(window.localStorage, itemKey, consentType)

export const setSessionStorageWithConsent = (itemKey, itemValue, consentType) =>
  setStorage(window.sessionStorage, itemKey, itemValue, consentType)

export const getSessionStorageWithConsent = (itemKey, consentType) =>
  getStorage(window.sessionStorage, itemKey, consentType)

export const removeSessionStorageWithConsent = (itemKey, consentType) =>
  removeStorage(window.sessionStorage, itemKey, consentType)
