class FavoritesStore {
  favoritedItemIds = {}

  addFavorites(newFavoritedItemIds) {
    newFavoritedItemIds.forEach((fav) => {
      this.favoritedItemIds[fav.item_id] = true
    })
    this.emitEvent()
  }

  removeFavoritedItemId(itemId) {
    delete this.favoritedItemIds[itemId]
    this.emitEvent()
  }

  addFavoritedItemId(itemId) {
    this.favoritedItemIds[itemId] = true
    this.emitEvent()
  }

  isItemIdFavorited(itemId) {
    return !!this.favoritedItemIds[itemId]
  }

  emitEvent() {
    const event = new Event("favoritesStoreUpdated")
    window.dispatchEvent(event)
  }
}

const favoritesStoreInstance = new FavoritesStore()

export default favoritesStoreInstance
