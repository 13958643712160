import { Controller } from "@hotwired/stimulus"
import CartEntrySummaryStore from "../stores/cart_entry_summary_store"

export default class extends Controller {
  static values = {
    cartEntry: Object,
  }

  connect() {
    CartEntrySummaryStore.saveCartEntry(this.cartEntryValue)
  }
}
