import { Controller } from "@hotwired/stimulus"
import { sendAnalyticsEvent } from "@envato/gtm-analytics"

// On connect, `ImpressionTracker` class  picks up any DOM elements with
// `data-analytics-view-payload` attributes. And when those elements appear
// in browser viewport, the tracker delivers the encoded JSON payload contained
// in the value of the attribute to Google Analytics 4.
//
// Clicks on promotions are handled by Market Click Tracking at app/javascript/packs/gtm_measurements.js.
export default class extends Controller {
  static targets = ["promotion", "item"]

  connect() {
    this.watchForImpression()
  }

  disconnect() {
    this.stopWatchingForImpression()
  }

  watchForImpression() {
    this.observer = new IntersectionObserver(this.onIntersection.bind(this))

    for (const element of this.promotionTargets) {
      this.observer.observe(element)
    }

    for (const element of this.itemTargets) {
      this.observer.observe(element)
    }
  }

  stopWatchingForImpression() {
    for (const element of this.promotionTargets) {
      this.observer.unobserve(element)
    }

    for (const element of this.itemTargets) {
      this.observer.unobserve(element)
    }
  }

  onIntersection(entries) {
    for (const entry of entries) {
      if (!entry.isIntersecting) continue

      this.onView(entry.target)
      this.observer.unobserve(entry.target)
    }
  }

  onView(element) {
    if (element.dataset.analyticsViewed) return

    const payload = this.viewPayload(element)
    if (!payload) return

    sendAnalyticsEvent(payload)

    // useful for human & Cypress to know that an element has been viewed
    element.dataset.analyticsViewed = true
  }

  viewPayload(element) {
    try {
      return JSON.parse(element.dataset.analyticsViewPayload)
    } catch (e) {
      if (document.body.dataset.railsEnv) {
        const errorTracker = window.Rollbar || console
        errorTracker.error("ImpressionTracker error", e)
      }
      return undefined
    }
  }
}
