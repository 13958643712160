import { removeCookieWithDefaultOptions } from "./cookies"
import { Consent, userHasOptedOutOfCookiesForCategory } from "./consent"
import { loadScript } from "./load_script"

// We load the GA script immediately unless the user
// has already declined consent for statistics
export const loadGAScript = () => {
  const userHasOptedOut = userHasOptedOutOfCookiesForCategory(Consent.statistics)
  if (!userHasOptedOut && process.env.LOAD_GOOGLE_ANALYTICS === "true") {
    loadScript("https://www.google-analytics.com/analytics.js")
  }
}

// On declining statistics consent, we disable
// tracking and delete GA-related storage
export const handleGAOptedOutEvent = () => {
  if (typeof window !== "undefined") {
    window.addEventListener("CookiebotOnAccept", handleCookiebotAcceptDeclineEvent)
    window.addEventListener("CookiebotOnDecline", handleCookiebotAcceptDeclineEvent)
  }
}

// Private //

const handleCookiebotAcceptDeclineEvent = () => {
  if (userHasOptedOutOfCookiesForCategory(Consent.statistics)) {
    removeTrackerStorage()
  }
}

const removeTrackerStorage = () => {
  const GA_LOCAL_STORAGE_KEY = "ga:clientId"
  const trackingId = document.body.dataset.googleAnalyticsId
  const path = "/"

  // Explicitly disable GA as per documentation as an extra precaution
  // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
  window[`ga-disable-${trackingId}`] = true

  // Manually delete any existing GA cookies
  ;["_ga", "_gid", "_gat"].forEach((cookieName) => removeCookieWithDefaultOptions(cookieName, { path }))

  try {
    // eslint-disable-next-line storage/no-localstorage
    localStorage.removeItem(GA_LOCAL_STORAGE_KEY)
  } catch (error) {
    // ignore as it just means we don't have access to local storage
  }
}
